import React from 'react'
import { addSuccessAlert } from '../../../../utils/helpers/alerts'
import Modal from '../../../UI/Modal'
import {
  ELEMENT_TYPES,
  GROUP_PARENT_ELEMENT_TYPES,
} from '../../helpers/elementHelper'
import useSelectedNode from '../../hooks/useSelectedNode'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'

const DeleteElementModal = ({
  id,
  appendTo,
  element,
  submitCallback,
  cancelCallback,
}) => {
  const selectedNode = useSelectedNode()
  const { removeElement } = useAddUpdateElement()
  const kind = ELEMENT_TYPES.find(
    (el) =>
      el.type ===
      (element.groupKind && GROUP_PARENT_ELEMENT_TYPES.includes(element.kind)
        ? element.groupKind
        : element.kind)
  ).title

  const handleSubmit = () => {
    removeElement(element, selectedNode).then(() => {
      addSuccessAlert({
        icon: 'success',
        title: `${kind} successfully deleted`,
      })

      submitCallback()
    })
  }

  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={`Are you sure you want to delete this ${kind.toLowerCase()}?`}
      subtitle="This action cannot be undone."
      useDefaultButtons
      textAssertive
      submitButtonText="Yes, I'm sure"
      cancelHandler={cancelCallback}
      submitHandler={handleSubmit}
    />
  )
}
export default DeleteElementModal
