import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'

let draftTmer

const TransparentInput = forwardRef(
  (
    {
      onConfirm = () => {},
      onCancel = () => {},
      placeholder = 'Add a label...',
      className = '',
      minHeight = 37,
      focusedMinHeight = 37,
      value,
      multiline = false,
      style = {},
      ...props
    },
    outerRef
  ) => {
    const [hovered, setHovered] = useState(false)
    const [focused, setFocused] = useState(false)
    const innerRef = useRef(null)
    useImperativeHandle(outerRef, () => innerRef.current, [])

    const setValue = (value) => {
      if (props.type === 'contentEditable') {
        innerRef.current.innerHTML = value ?? ''
      } else {
        innerRef.current.value = value
      }
    }

    const handleCancel = () => {
      setValue(value)
      clearDraft()
      onCancel()
    }

    const handleConfirm = () => {
      clearDraft()
      onConfirm({
        target: { name: props.name, value: innerRef.current.innerHTML },
      })
      setHovered(false)
      innerRef.current.blur()
      setTimeout(() => {
        clearTimeout(draftTmer)
      }, 0)
    }

    const saveDraft = () =>
      (innerRef.current.dataset.draftValue = innerRef.current.innerHTML)

    const restoreDraft = () => {
      if (innerRef.current.dataset.draftValue) {
        if (props.type === 'contentEditable')
          innerRef.current.innerHTML = innerRef.current.dataset.draftValue
        else innerRef.current.value = innerRef.current.dataset.draftValue
      }
    }

    const clearDraft = () => (innerRef.current.dataset.draftValue = '')

    const renderInput = () => {
      const genericProps = {
        placeholder,
        className: `border-0 border-radius mb-0 text-normal text-dark pl-1 tracking-tighter 
          ${hovered ? 'bg-stable-lightest' : ''} 
          ${focused ? 'bg-stable-lighter' : ''} 
          ${multiline ? 'pb-3' : ''}
          ${!hovered && !focused ? 'bg-transparent' : ''} 
          ${className}
        `,
        style: {
          ...(!multiline ? { paddingRight: '60px' } : {}),
          ...style,
        },
        ...props,
        ref: innerRef,
        value,
        onKeyPress: (e) => {
          if (e.key === 'Enter' && !multiline) {
            e.preventDefault()
            handleConfirm()
            return false
          }
        },
        onFocus: (e) => {
          setFocused(true)

          // restore draft value from HTML data attribute
          restoreDraft()

          const currentValue =
            props.type === 'contentEditable'
              ? e.target.innerHTML
              : e.target.value
          props?.onFocus?.(currentValue)
        },
        onBlur: (e) => {
          const currentValue =
            props.type === 'contentEditable'
              ? e.target.innerHTML
              : e.target.value

          draftTmer = setTimeout(() => {
            // save draft value in HTML data attribute
            if (value !== currentValue) {
              saveDraft()
              setValue(value)
            }
          }, 300)
          setTimeout(() => {
            setFocused(false)
          }, 300)

          props?.onBlur?.(currentValue)
        },
      }
      if (props.type === 'contentEditable')
        return (
          <div
            {...genericProps}
            contentEditable="plaintext-only"
            suppressContentEditableWarning={true}
            tabIndex={100}
            style={{
              minHeight: `${focused ? focusedMinHeight : minHeight}px`,
              overflow: 'hidden',
              outline: 'none',
              padding: '0.5rem',
              transition: 'all 0.2s',
              wordBreak: 'break-word',
              ...genericProps.style,
            }}>
            {value}
          </div>
        )

      return (
        <input
          type="text"
          {...genericProps}
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleConfirm()
          }}
          style={genericProps.style}
        />
      )
    }

    useEffect(() => {
      setValue(value)
    }, [value])

    return (
      <div
        className="w-100 h-100"
        style={{ position: 'relative', maxHeight: '100%' }}
        hidden={props.hidden}
        onMouseEnter={setHovered}
        onMouseLeave={() => {
          setHovered(false)
        }}>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            bottom: `${props.type === 'contentEditable' ? 1 : 0}px`,
          }}
          className="bg-stable-lighter pl-0-5 text-xxlarge"
          hidden={!focused}>
          <button
            onClick={handleCancel}
            className="text-stable-dark cursor-pointer">
            <FaTimes />
          </button>
          <button
            onClick={handleConfirm}
            className="text-assertive ml-1 cursor-pointer">
            <FaCheckCircle />
          </button>
        </div>
        {renderInput()}
      </div>
    )
  }
)

export default TransparentInput
