import React, { lazy, Suspense, useContext, useEffect } from 'react'
import ScenarioEditorProvider, {
  ScenarioEditorContext,
} from '../components/FlowV2/context/ScenarioEditorProvider'
import withApollo from '../hooks/withApollo'
import { Route, Link, Routes, useLocation } from 'react-router-dom'
import {
  EDITOR_TABS,
  useEditorActiveTab,
} from '../components/FlowV2/helpers/editorHelper'
import { ReactFlowProvider, useNodes } from 'reactflow'
import FlowEditor from '../components/FlowV2/tabs/FlowEditor'
import DataTooltip from '../components/UI/DataTooltip'
import useCurrentUser from '../hooks/useCurrentUser'
import LoadingSpinner from '../components/UI/LoadingSpinner'

const TestModal = lazy(
  () => import('../components/FlowV2/components/FlowEditor/Publish/TestModal')
)
const PublishModal = lazy(
  () =>
    import('../components/FlowV2/components/FlowEditor/Publish/PublishModal')
)
const TEST_PUBLISH_DISABLE_MSG =
  'You can not test or publish a scneario without any scenes. Start by creating your first scene!'

const ScenarioEditor = () => {
  const activeTab = useEditorActiveTab()
  const { scenario } = useContext(ScenarioEditorContext)
  const location = useLocation()
  const [, currentClient] = useCurrentUser()
  const nodes = useNodes().filter((node) => node.type === 'Scene')

  const getTestButton = () => (
    <button
      className="o-button o-button--royal o-button--auto-width text-normal text-bold mr-1"
      data-open="test-modal"
      disabled={nodes.length === 0}>
      Test
    </button>
  )

  const getPublishButton = (disabled) => {
    const getButton = (disabled) => (
      <button
        className="o-button o-button--success o-button--auto-width text-normal text-bold"
        data-open="publish-modal"
        disabled={disabled}>
        Publish
      </button>
    )

    if (disabled) return getButton(true)

    return (
      <>
        {currentClient?.canPublishScenarios ? (
          getButton()
        ) : (
          <DataTooltip
            position="bottom"
            title="You do not have publishing slots open to publish this scenario. Contact the owner of this workspace or go to settings to increase the number of publishing slots">
            {getButton(true)}
          </DataTooltip>
        )}
      </>
    )
  }

  useEffect(() => {
    // Add a trailing slash to the URL if it's missing
    const regex = /^\/scenarios\/\d+$/
    if (regex.test(window.location.pathname))
      window.history.replaceState(null, null, window.location.pathname + '/')
  }, [])

  if (!scenario) return null

  return (
    <div id="scenario-editor">
      <div className="c-sub-menu" style={{ marginTop: '-2.5rem' }}>
        <div className="grid-container fluid flex-container align-middle align-justify">
          <div className="c-sub-menu__inner">
            <div className="c-sub-menu__filter">
              <p>{scenario.name}</p>
            </div>
            <ul className="c-sub-menu__list">
              {EDITOR_TABS.map((tab) => (
                <li
                  key={tab.key}
                  className={`c-sub-menu__list-item ${
                    activeTab.key === tab.key ? 'active' : ''
                  }`}>
                  <Link to={`/${tab.key}`} className="c-sub-menu__list-link">
                    {tab.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-container align-middle">
            {nodes.length > 0 ? (
              <>
                {getTestButton()}
                {getPublishButton()}
              </>
            ) : (
              <>
                <DataTooltip position="bottom" title={TEST_PUBLISH_DISABLE_MSG}>
                  {getTestButton()}
                </DataTooltip>
                <DataTooltip position="bottom" title={TEST_PUBLISH_DISABLE_MSG}>
                  {getPublishButton(true)}
                </DataTooltip>
              </>
            )}
          </div>
        </div>
      </div>

      <div
        hidden={EDITOR_TABS.some(
          (tab) => tab.isRoute && location.pathname.includes(tab.key)
        )}>
        <FlowEditor />
      </div>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {EDITOR_TABS.filter((tab) => tab.isRoute).map((tab) => (
            <Route key={tab.key} path={`${tab.key}/*`} element={tab.content} />
          ))}
        </Routes>
      </Suspense>

      <div id="scenario-modals" />
      {nodes.length > 0 && (
        <Suspense fallback={<LoadingSpinner />}>
          {/* Please do not remove the following wrapper div */}
          <div>
            <PublishModal />
          </div>

          <TestModal />
        </Suspense>
      )}
    </div>
  )
}

const ScenarioEditorWrapper = ({ scenarioId }) => {
  return (
    <ScenarioEditorProvider scenarioId={scenarioId}>
      <ReactFlowProvider>
        <ScenarioEditor scenarioId={scenarioId} />
      </ReactFlowProvider>
    </ScenarioEditorProvider>
  )
}

export default withApollo(ScenarioEditorWrapper)
