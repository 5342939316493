import { useLazyQuery, useMutation } from '@apollo/client'
import {
  addElementMutation,
  getSceneWithElementsQuery,
  removeElementMutation,
  updateElementMutation,
} from '../../../apollo/query/scenes'
import { handleApolloError } from '../../../utils/errors'
import { getConnectedNodes, setNode } from '../helpers/nodeHelper'
import { useReactFlow, useUpdateNodeInternals } from 'reactflow'
import {
  getDisplayedNodeElements,
  getLatestNumber,
  GROUP_CHILD_ELEMENT_TYPES,
  INTERACTIVE_ELEMENT_TYPES,
  VIDEO_ELEMENT_TYPES,
} from '../helpers/elementHelper'
import { removeEdge } from '../helpers/edgeHelper'

const useAddUpdateElement = () => {
  const reactFlow = useReactFlow()
  const { getEdges, setEdges, deleteElements } = reactFlow
  const updateNodeInternals = useUpdateNodeInternals()
  const [reloadScene] = useLazyQuery(getSceneWithElementsQuery, {
    fetchPolicy: 'no-cache',
  })
  const [_updateElement] = useMutation(updateElementMutation, {
    onError: handleApolloError,
  })

  const getElementNumber = (obj, node) => {
    if (!INTERACTIVE_ELEMENT_TYPES.includes(obj.variables.kind)) return -1
    return (
      obj.variables.number ?? getLatestNumber(node, obj.variables.groupUuid) + 1
    )
  }

  const addElement = (obj, node, isDuplicate) => {
    const number = getElementNumber(obj, node)

    const anchorX = obj.variables.anchorX ?? 0
    const anchorY = obj.variables.anchorY ?? 0

    return _addElement({
      ...obj,
      variables: {
        ...obj.variables,
        number,
        anchorX,
        anchorY,
      },
      isDuplicate
    })
  }

  const fillDefaultValues = (element) => ({
    ...element,
    showInLookDirection: element.showInLookDirection ?? false,
    label: element.label ?? '',
  })

  const updateElement = (obj) => {
    const newElement = fillDefaultValues(obj.variables)

    // updating existing
    const nodes = reactFlow.getNodes()
    const node = nodes.find((n) =>
      n.data.elements?.find((el) => el.id === newElement.id)
    )

    // if node is not found, it means that the node is not in the current view yet
    // this condition is useful in duplicate scenes
    if (node) {
      const oldElement = node.data.elements.find(
        (el) => el.id === newElement.id
      )

      node.data.elements = [
        ...node.data.elements.filter((el) => el.id !== newElement.id),
        newElement,
      ]
      setNode(reactFlow, node)

      // updating existing edges
      if (newElement.randomizedLinkToIds.length) {
        setTimeout(() => {
          setEdges(
            getEdges().map((e) => {
              if (newElement.randomizedLinkToIds.includes(e.target)) {
                return {
                  ...e,
                  data: {
                    ...e.data,
                    points: newElement.points,
                  },
                }
              }
              return e
            })
          )
        }, 100)
      } else {
        const elementEdges = getEdges().filter((e) =>
          e.id.startsWith(newElement.id)
        )
        if (elementEdges) {
          if (newElement.linkToEnding)
            deleteElements({
              edges: elementEdges.map((e) => ({ id: e.id })),
            })
          else
            setTimeout(() => {
              setEdges(
                getEdges().map((e) => {
                  if (elementEdges.find((ee) => ee.id === e.id)) {
                    return {
                      ...e,
                      data: {
                        ...e.data,
                        points: newElement.points,
                      },
                    }
                  }
                  return e
                })
              )
            }, 200)
        }
      }

      setTimeout(() => {
        updateNodeInternals(node.id)
        if (newElement.linkToId) updateNodeInternals(newElement.linkToId)
        if (oldElement.linkToId) updateNodeInternals(oldElement.linkToId)

        const randomizerLinks = new Set(
          oldElement.randomizedLinkToIds,
          newElement.randomizedLinkToIds
        )
        ;[...randomizerLinks]
          .filter((sceneId) => Number(sceneId))
          .forEach((sceneId) => updateNodeInternals(sceneId))

        // If a node disconnect from target node and connect to ending, the sources nodes connected to target node should be updated
        if (oldElement.linkToId && newElement.linkToEnding) {
          const connectedNodes = getConnectedNodes(
            reactFlow,
            oldElement.linkToId
          )
          connectedNodes.forEach((nodeId) => updateNodeInternals(nodeId))
        }
      }, 300)
    }

    return _updateElement({
      ...obj,
      variables: {
        ...newElement,
      },
    }).then(
      ({
        data: {
          updateElement: { element },
        },
      }) => {
        return element
      }
    )
  }

  const [_addElement] = useMutation(addElementMutation, {
    onError: handleApolloError,
    onCompleted: ({ addElement }, { variables, isDuplicate }) => {
      reloadScene({
        variables: {
          id: variables.sceneId,
        },
      }).then(({ data: { scene } }) => {
        setTimeout(() => {
          const node = reactFlow.getNode(scene.id)
          checkAndReorderElements({ ...node, data: scene })
        }, 500)

        if (isDuplicate) return

        scene.elements
          .filter(
            (el) =>
              el.anchorX === null &&
              el.defaultAnchorX !== null &&
              VIDEO_ELEMENT_TYPES.includes(el.kind)
          )
          .forEach((el) => {
            updateElement({
              variables: {
                ...el,
                anchorX: el.defaultAnchorX,
                anchorY: el.defaultAnchorY,
              },
            })
          })
      })
    },
  })

  const checkAndReorderElements = (node) => {
    const displayedElements = getDisplayedNodeElements(node.data)
    const allElements = node.data.elements
    let interactiveElements = allElements.filter((el) =>
      INTERACTIVE_ELEMENT_TYPES.includes(el.kind)
    )
    const nonInteractiveElements = allElements.filter(
      (el) => !INTERACTIVE_ELEMENT_TYPES.includes(el.kind) && el.number !== -1
    )

    let changedElements = [
      ...displayedElements
        .map((el) => {
          const answerElements = [...el.answerElements]
          delete el.answerElements
          return [el, ...(answerElements ?? [])]
        })
        .flat()
        .filter((el) => interactiveElements.find((iel) => iel.id === el.id))
        .map((el, i) => ({
          ...el,
          number: i + 1,
        }))
        .filter((el) => {
          return (
            el.number !==
            interactiveElements.find((iel) => iel.id === el.id).number
          )
        }),
      // non interactive elements should have number -1
      ...nonInteractiveElements.map((el) => ({
        ...el,
        number: -1,
      })),
    ]

    if (changedElements.length) {
      changedElements.forEach((el) => {
        _updateElement({
          variables: fillDefaultValues(el),
        })
      })

      const newAllElements = [
        ...allElements.filter(
          (el) => !changedElements.find((cel) => cel.id === el.id)
        ),
        ...changedElements,
      ]

      const newNode = {
        ...node,
        data: {
          ...node.data,
          elements: newAllElements,
        },
      }

      setNode(reactFlow, newNode)

      return newNode
    }

    return null
  }

  const [_removeElement] = useMutation(removeElementMutation, {
    onError: handleApolloError,
  })

  const removeElement = (element, node) => {
    if (element.groupUuid && ['QUESTION', 'TOOLTIP'].includes(element.kind)) {
      const answerElements = node.data?.elements?.filter(
        (el) =>
          GROUP_CHILD_ELEMENT_TYPES.includes(el.kind) &&
          element.groupUuid === el.groupUuid
      )
      answerElements?.forEach((el) => {
        _removeElement({
          variables: { id: el.id },
        })
      })
    }

    removeEdge(reactFlow, element.id)

    let newElements = node.data.elements.filter((el) => el.id !== element.id)
    setNode(reactFlow, {
      ...node,
      data: {
        ...node.data,
        elements: newElements,
      },
    })

    if (element.linkToId)
      setTimeout(() => {
        updateNodeInternals(element.linkToId)
      }, 200)

    checkAndReorderElements({
      ...node,
      data: { ...node.data, elements: newElements },
    })

    return _removeElement({
      variables: { id: element.id },
    })
  }

  return { updateElement, addElement, removeElement, checkAndReorderElements }
}

export default useAddUpdateElement
