import React, { useCallback } from 'react'
import { FaFlagCheckered } from 'react-icons/fa'
import { convertDuration } from '../../utils/format'
// import Dropdown from '../UI/Menu/Dropdown'
import {
  getDisplayedNodeElements,
  getLetterFromNumber,
  getPointClass,
} from '../FlowV2/helpers/elementHelper'
import { padZero } from '../../utils/helpers/javascript'

const Circle = ({ className = '' }) => {
  return (
    <span
      className={className}
      style={{
        display: 'inline-block',
        width: '21px',
        height: '21px',
        border: '4px solid #ffffff',
        marginRight: '6px',
        borderRadius: '50%',
      }}></span>
  )
}
/*const OptionDropdown = ({ id }) => {
  const [isHovered, setIsHovered] = useState(false)

  if (isHovered) {
    return (
      <Dropdown
        id={`scene-menu-${id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        button={
          <button
            data-toggle={`scene-menu-${id}`}
            className="cursor-pointer text-stable-dark p-1">
            <FaEllipsisH />
          </button>
        }>
        <>
          <li
            className="o-dropdown__list-item text-normal text-dark"
            onClick={() => {}}>
            View scene analysis
          </li>
        </>
      </Dropdown>
    )
  }
  return (
    <button
      className="cursor-pointer text-stable-dark p-1"
      data-toggle={`scene-menu-${id}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <FaEllipsisH />
    </button>
  )
}*/

const Row = ({ event, startEventRecordedAt, lastEvent, scoringSystem }) => {
  if (event.kind === 'START') return <></>

  const { answer, scene } = event

  const getSceneNumber = () => {
    if (event.kind === 'ENDING') return <FaFlagCheckered className="m-0" />
    return scene?.number
  }
  const getSceneName = () => {
    if (event.kind === 'ENDING') return 'Ending'
    return scene?.name
  }

  const getAnswerTitle = useCallback(() => {
    const element = scene.elements.find((el) => el.id === answer.id)
    return `${getLetterFromNumber(answer.number)}.
    ${
      event.kind === 'TIMER'
        ? `Timer is set to ${padZero(element.timerMinutes ?? 0)}: ${padZero(element.timerSeconds ?? 0)}`
        : answer.name
    }`
  }, [event])

  return (
    <div>
      <div className="text-stable-dark text-normal flex-container align-middle">
        <Circle className={getPointClass(answer?.points, scoringSystem)} />
        {convertDuration(
          (new Date(event.recordedAt) -
            new Date(startEventRecordedAt).getTime()) /
            1000
        )}
      </div>
      <div style={{ paddingLeft: 8 }}>
        <div
          className="border-left"
          style={{
            borderWidth: 5,
            paddingLeft: 15,
            paddingBottom: lastEvent ? 0 : 15,
          }}>
          <div className="flex-container align-middle pt-1">
            <span
              className={`o-label text-center ${
                event.kind === 'ENDING'
                  ? 'o-label--dark o-label--scene-ending'
                  : 'o-label--stable-dark'
              }`}>
              {getSceneNumber()}
            </span>
            <span className="text-bold ml-1 text-normal">{getSceneName()}</span>
          </div>
          {answer && (
            <div className="border pl-2 pr-2 pt-1 pb-1 border-radius mt-1 text-normal flex-container align-middle align-justify">
              <div className="text-truncate" title={getAnswerTitle()}>
                {getAnswerTitle()}
              </div>
              <div className="flex-container align-middle ml-1">
                <span
                  className={`text-bold text-${getPointClass(answer.points, scoringSystem)}`}>
                  {answer.points}
                </span>
                {/* <OptionDropdown id={answer.id} /> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const ScenesEvents = ({ events: _events, scoringSystem }) => {
  const events = [..._events].sort((a, b) => {
    if (b.kind === 'ENDING') return -1
    return 0
  })

  return (
    <div>
      {events.map((event, index) => (
        <Row
          key={event.scene?.id || event.kind}
          event={event}
          startEventRecordedAt={events[0].recordedAt}
          lastEvent={events.length - 1 === index}
          scoringSystem={scoringSystem}
        />
      ))}
    </div>
  )
}

export default ScenesEvents
