/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaGripVertical } from 'react-icons/fa'
import Checkbox from './UI/Form/Checkbox'
import { default as S } from '../utils/lang/en.json'
import useFoundation from '../hooks/useFoundation'
import { isIntersecting } from '../utils/helpers/javascript'

const ROW_HEIGHT = 50

const Table = ({
  data,
  tablePrefix,
  headers,
  sortOn,
  scrollHandler,
  selectedItems = [],
  selectable,
  loadMore,
  loadMoreHandler,
  selectAllHandler,
  sortHandler,
  rowHandler,
  handleEntireRow = false,
  rowSuffix,
  checkboxHandler,
  placeholderRows = 15,
  type,
  tableScroll = true,
  columnWidths,
  draggableRows,
  onOrderChange = () => {},
  groupedBy,
  isSmall = false,
}) => {
  let mouseDownX
  let mouseDownY

  const ref = useFoundation()
  const [loadingMore, setLoadingMore] = useState(false)
  const dragged = useRef(null)
  const templateRef = useRef(null)
  const destElemRef = useRef(null)

  const createColgroups = () => {
    if (!columnWidths) return null
    const colgroups = [
      ...(selectable ? [`${ROW_HEIGHT}px`] : []),
      ...(draggableRows ? ['40px'] : []),
      ...columnWidths,
    ].map((width, index) => (
      <col key={index} style={{ width, minWidth: width }} />
    ))
    return <colgroup>{colgroups}</colgroup>
  }

  const createHeaders = () => {
    const icon = sortOn?.isAscending ? (
      <FaChevronUp className="o-table__header--icon" />
    ) : (
      <FaChevronDown className="o-table__header--icon" />
    )
    const renderedHeaders = headers.map((header, index) => {
      if (index === 0 && rowSuffix) {
        return (
          <Fragment key={index}>
            <th
              className="o-table__header o-table__header--nowrap o-table__header--sortable o-table__header--border"
              onClick={() => sortHandler?.(header)}>
              {S.table[type].headers[header]}{' '}
              {sortOn?.value === header ? icon : ''}
            </th>
            <th className="o-table__header o-table__header--nowrap o-table__header--border"></th>
          </Fragment>
        )
      }
      return (
        <th
          className={`o-table__header o-table__header--nowrap o-table__header--sortable o-table__header--border 
            ${index === 0 && (draggableRows || isSmall) ? 'pl-0' : ''}
          `}
          key={index}
          onClick={() => sortHandler?.(header)}>
          {header?.render ?? S.table[type].headers[header]}{' '}
          {sortOn?.value === header ? icon : ''}
        </th>
      )
    })

    if (selectable) {
      const allSelected = !!(
        data.length && data.every(({ id }) => selectedItems.includes(id))
      )
      renderedHeaders.unshift(
        <th
          key="checkbox"
          className={`o-table__data--checkbox checkbox-fixed-width o-table__action o-table__selection o-table__header--border 
            ${draggableRows || isSmall ? 'pl-0' : ''}`}>
          <div className="flex-container align-middle align-center">
            <Checkbox
              checked={allSelected}
              clickHandler={() => {
                if (data.length) {
                  selectAllHandler(!allSelected ? data.map((i) => i.id) : [])
                }
              }}
            />
          </div>
        </th>
      )
    } else {
      if (!draggableRows && !isSmall)
        renderedHeaders.unshift(
          <th key="emptytd" className="o-table__header--border"></th>
        )
    }

    if (draggableRows && !isSmall)
      renderedHeaders.unshift(
        <th key="checkbox" className="o-table__header--border"></th>
      )

    return <tr className="o-table__row border-none">{renderedHeaders}</tr>
  }

  /* Drag & Drop functions*/
  const getRows = () =>
    ref.current.querySelectorAll(
      'tr:not(.o-table__row--placeholder):not(.o-table__row--group)'
    )
  const getAllRows = () =>
    ref.current.querySelectorAll('tr:not(.o-table__row--placeholder)')

  const moveRow = (x, y) => {
    const dragElem = dragged.current
    if (dragElem) {
      dragElem.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0)'

      let dPos = dragElem.getBoundingClientRect(),
        currStartY = dPos.y,
        currEndY = currStartY + dPos.height,
        rows = getRows()

      destElemRef.current = null
      for (let i = 0; i < rows.length; i++) {
        let rowElem = rows[i],
          rowSize = rowElem.getBoundingClientRect(),
          rowStartY = rowSize.y,
          rowEndY = rowStartY + rowSize.height
        if (
          dragElem !== rowElem &&
          isIntersecting(currStartY, currEndY, rowStartY, rowEndY) &&
          Math.abs(currStartY - rowStartY) < rowSize.height / 2
        ) {
          rowElem.classList.add('o-table__row--dragover')
          destElemRef.current = rows[i]
        } else {
          rowElem.classList.remove('o-table__row--dragover')
        }
      }
    }
  }

  const handleMouseDown = (e) => {
    const tr = e.target.closest('tr')
    dragged.current = tr

    mouseDownX = e.clientX
    mouseDownY = e.clientY + 50

    for (var i = 0; i < tr.children.length; i++) {
      let td = tr.children[i]
      td.style.width = getComputedStyle(td).width
      td.style.height = getComputedStyle(td).height
    }

    tr.classList.add('dragging')
    const clonedRow = templateRef.current.querySelector('tr').cloneNode(true)
    tr.parentNode.insertBefore(clonedRow, tr)

    moveRow(0, -ROW_HEIGHT - 1)
  }

  const handleMouseMove = (e) => {
    if (!dragged.current) return false
    const tr = dragged.current

    const mouseX = e.clientX - mouseDownX
    const mouseY = e.clientY - mouseDownY

    if (mouseX < 0 || mouseX > tr.getBoundingClientRect().width) {
      return false
    }
    moveRow(mouseX, mouseY)
  }

  const handleMouseUp = () => {
    const tr = dragged.current
    if (tr) {
      tr.classList.remove('dragging')

      if (destElemRef.current) {
        const allRows = getAllRows()
        const originalSourceIndex = Array.from(allRows).indexOf(tr)
        const originDestIndex = Array.from(allRows).indexOf(destElemRef.current)

        if (originalSourceIndex !== originDestIndex) {
          const sourceIndex = Array.from(getRows()).indexOf(tr) - 1 // -1 because of thead
          let destIndex = Array.from(getRows()).indexOf(destElemRef.current) - 1
          if (destIndex !== sourceIndex) onOrderChange(sourceIndex, destIndex)
        }
      }

      moveRow(0, 0)
      const placeholder = tr
        .closest('table')
        .querySelector('tr.o-table__row--placeholder')
      if (placeholder) placeholder.remove()
    }

    destElemRef.current = null
    dragged.current = null
  }

  useEffect(() => {
    setLoadingMore(false)
  }, [data])

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  const createRows = (data, groupedBy) => {
    if (groupedBy) {
      return Object.keys(data).map((key) => {
        if (!data[key].data?.length) return null
        return (
          <Fragment key={key}>
            <tr className="o-table__row o-table__row--group">
              <td className="pt-4-5" colSpan={(headers.length + 2).toString()}>
                {data[key].title}
              </td>
            </tr>
            {createRows(data[key].data, null)}
          </Fragment>
        )
      })
    }
    return data.map((row, index) => {
      const cells = Object.keys(row)
        .map((key, i) => {
          if (!headers.some((h) => h === key || h?.name === key)) return null
          const firstItem = key === headers[0]
          const render = row[key]
            ? row[key].render || row[key].value || row[key]
            : '-'
          if (firstItem && rowSuffix) {
            return (
              <Fragment key={`${row.id}-${i}`}>
                <td
                  onClick={() =>
                    !row.isDisabled &&
                    !handleEntireRow &&
                    rowHandler &&
                    rowHandler(row.id)
                  }
                  className={`o-table__data o-table__data--custom o-table__data--first-item ${
                    rowHandler ? 'cursor-pointer' : ''
                  }`}>
                  {row.isDisabled ? (
                    <span
                      data-tooltip
                      data-position="bottom"
                      data-alignment="center"
                      title={S.strings.publishingCannotOpen}>
                      <a>{render}</a>
                    </span>
                  ) : rowHandler ? (
                    <a>{render}</a>
                  ) : (
                    <span className="cursor-default">{render}</span>
                  )}
                </td>
                <td className="o-table__data text-stable-dark o-table__data--custom">
                  {!row.isDisabled && rowSuffix(row.id)}
                </td>
              </Fragment>
            )
          }
          if (firstItem) {
            return (
              <td
                key={`${row.id}-${i}`}
                onClick={() =>
                  !handleEntireRow && rowHandler && rowHandler(row.id)
                }
                className="o-table__data o-table__data--custom o-table__data--first-item">
                {rowHandler ? (
                  <a>{render}</a>
                ) : (
                  <span className="cursor-default">{render}</span>
                )}
              </td>
            )
          }
          return (
            <td
              key={`${row.id}-${i}`}
              className="o-table__data text-stable-dark o-table__data--custom">
              {render}
            </td>
          )
        })
        .filter((i) => i !== null)
      while (cells.length < headers.length) {
        cells.push(<td key={headers.length - cells.length}></td>)
      }
      return (
        <tr
          className={`o-table__row ${
            handleEntireRow
              ? 'cursor-pointer o-table__row--entire-clickable'
              : ''
          } ${
            selectedItems.includes(row.id) && !selectable
              ? 'o-table__row--selected'
              : ''
          }`}
          key={index}
          disabled={row.isDisabled}
          onClick={() => handleEntireRow && rowHandler && rowHandler(row.id)}>
          {draggableRows && (
            <td className="o-table__drag-handle" onMouseDown={handleMouseDown}>
              <div className="flex-container align-middle align-center text-stable-dark">
                <FaGripVertical />
              </div>
            </td>
          )}
          {selectable ? (
            <td
              className={`o-table__data--checkbox checkbox-fixed-width ${draggableRows ? 'pl-0' : ''}`}>
              <div className="flex-container align-middle align-center">
                <Checkbox
                  checked={selectedItems.includes(row.id)}
                  disabled={row.isDisabled}
                  clickHandler={() => {
                    checkboxHandler(row.id)
                  }}
                />
              </div>
            </td>
          ) : (
            <>{!draggableRows && !isSmall && <td></td>}</>
          )}
          {cells}
        </tr>
      )
    })
  }

  const placeHolder = () => {
    const celltemplate = (i) => (
      <td key={i} className="m-0 p-0">
        <div className="o-table__placeholder--cell">
          <div className="o-table__data--loading"></div>
        </div>
      </td>
    )
    const cells = headers.length + 1

    const allcells = []
    for (let i = 0; i < cells; i++) {
      allcells.push(celltemplate(i))
    }
    const template = (i) => (
      <tr key={i} className="o-table__row">
        <td className="o-table__data--checkbox checkbox-fixed-width ">
          <Checkbox checked={false} disabled={true} />
        </td>
        {allcells}
      </tr>
    )
    const rows = placeholderRows
    const generated = []
    for (let i = 0; i < rows; i++) {
      generated.push(template(i))
    }
    return generated
  }

  if (!data.length && !groupedBy && !isSmall) {
    return (
      <div
        id="table-view"
        className={tableScroll ? 'o-table--scroll' : ''}
        ref={ref}
        onScroll={scrollHandler}>
        <table
          className={`o-table o-table--overflow o-table--hover o-table__selected 
            ${columnWidths?.length ? 'o-table-fixed-width' : ''}
            ${isSmall ? 'o-table--small' : ''}
          `}>
          {createColgroups()}
          <thead>{createHeaders()}</thead>
          <tbody>{placeHolder()}</tbody>
        </table>
      </div>
    )
  }
  return (
    <div
      id="table-view"
      className={tableScroll ? 'o-table--scroll' : ''}
      ref={ref}
      onScroll={scrollHandler}>
      <table
        data-testid="table"
        className={`o-table o-table--overflow o-table--hover o-table__selected 
          ${isSmall ? 'o-table--small' : ''} 
          ${columnWidths?.length ? 'o-table-fixed-width' : ''}
        `}
        onMouseMove={handleMouseMove}>
        {createColgroups()}
        <thead>{createHeaders()}</thead>
        <tbody data-testid="table-body">
          {tablePrefix ? (
            <tr>
              <td colSpan={(headers.length + 2).toString()} className="m-0 p-0">
                {tablePrefix}
              </td>
            </tr>
          ) : null}
          {createRows(data, groupedBy)}
        </tbody>
      </table>
      {loadMore && (
        <div
          className="cursor-pointer align-center"
          onClick={() => {
            setLoadingMore(true)
            loadMoreHandler()
          }}>
          <p className="o-table__load-more">
            {loadingMore ? 'Loading...' : 'Show more...'}
          </p>
        </div>
      )}
      <div ref={templateRef} hidden>
        <table>
          <tbody>
            <tr className="o-table__row o-table__row--placeholder">
              <td
                className="o-table__data"
                colSpan={(columnWidths?.length + 1).toString()}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default Table
