import React from 'react'
import Dropdown from '../../../UI/Menu/Dropdown'
import { FaChevronRight, FaEllipsisH, FaTrash } from 'react-icons/fa'
import DeleteElementModal from './DeleteElementModal'
import {
  ELEMENT_TYPES,
  GROUP_PARENT_ELEMENT_TYPES,
  INTERACTIVE_ELEMENT_TYPES,
} from '../../helpers/elementHelper'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import ButtonGroup from '../../../UI/Form/ButtonGroup/ButtonGroup'
import RandomizerModal from './RandomizerModal'
import DataTooltip from '../../../UI/DataTooltip'

const TYPES_HAS_CHANGE = ['DIRECTION', 'HOTSPOT', 'BUTTON']

const ElementCardDropdown = ({
  element,
  onCancelDelete,
  onConfirmDelete,
  position = 'bottom',
  deleteDisabled,
  deleteDisabledText = '',
}) => {
  const { updateElement } = useAddUpdateElement()

  const handleChangeElement = (e) => {
    const type = e.currentTarget.dataset.type
    updateElement({
      variables: {
        ...element,
        kind: type,
      },
    })
  }

  const handleDeleteElement = () => {
    onConfirmDelete()
  }

  const handleEndingElement = () => {
    const newElement = {
      ...element,
      linkToEnding: !element.linkToEnding,
      linkToId: null,
      randomizedLinkToIds: [],
    }
    updateElement({
      variables: newElement,
    })
  }

  const isParent =
    element.groupKind && GROUP_PARENT_ELEMENT_TYPES.includes(element.kind)

  const isInformationButton =
    element.groupKind === 'INFORMATION' && element.kind === 'BUTTON'

  const getDeleteButton = () => (
    <>
      <FaTrash className="mr-1 text-assertive" />
      Delete{' '}
      {ELEMENT_TYPES.find(
        (el) => el.type === (isParent ? element.groupKind : element.kind)
      ).title.toLowerCase()}
    </>
  )

  return (
    <>
      <Dropdown
        id={`element-menu-${element.id}`}
        alignment="right"
        offset={{ left: '20px', top: '0px' }}
        minWidth={200}
        position={position}
        button={
          element.groupUuid ? (
            <button
              className="pt-1 pb-1 pl-1-5 pr-1-5 o-button o-button--square-small o-button--secondary o-button--no-active o-button--light text-bold flex-container align-middle mb-0"
              data-toggle={`element-menu-${element.id}`}>
              <FaEllipsisH />
            </button>
          ) : (
            <ButtonGroup.Button
              last
              className="pt-1 pb-1 pl-1-5 pr-1-5 o-button o-button--square-small o-button--light o-button--no-active text-bold flex-container align-middle mb-0"
              data-toggle={`element-menu-${element.id}`}>
              <FaEllipsisH />
            </ButtonGroup.Button>
          )
        }>
        <>
          {INTERACTIVE_ELEMENT_TYPES.includes(element.kind) && (
            <>
              <li className="o-dropdown__list-item">
                <div className="flex-container align-middle align-justify">
                  <span>Ending</span>
                  <div className="o-switch">
                    <input
                      type="checkbox"
                      data-testid="ending-input"
                      name="linkToId"
                      checked={element.linkToEnding || false}
                      readOnly
                      id={`linkToId-${element.id}`}
                      className="o-switch__input"
                    />
                    <label
                      onClick={handleEndingElement}
                      htmlFor={`linkToId-${element.id}`}
                      className="o-switch__paddle"></label>
                  </div>
                </div>
              </li>
              <li
                className="o-dropdown__list-item"
                data-open={`randomizer-modal-${element.id}`}>
                Randomizer
              </li>
              {!isInformationButton && <li className="o-dropdown__divider" />}
            </>
          )}

          {!isInformationButton && (
            <>
              {TYPES_HAS_CHANGE.includes(element.kind) && (
                <>
                  <li
                    className="o-dropdown__list-item o-dropdown__item--open-dropdown opens-left"
                    style={{ position: 'relative' }}>
                    <div className="flex-container align-middle align-justify">
                      <span>Change element</span>
                      <FaChevronRight />
                    </div>
                    <ul
                      className="o-dropdown o-dropdown--right"
                      style={{ minWidth: '200px', top: '-5px' }}>
                      {TYPES_HAS_CHANGE.filter((el) => element.kind !== el).map(
                        (el) => {
                          const elRow = ELEMENT_TYPES.find((e) => e.type === el)
                          return (
                            <li
                              key={el}
                              onClick={handleChangeElement}
                              data-type={el}
                              className="o-dropdown__list-item text-normal text-dark flex-container align-middle">
                              {elRow.icon}
                              <span className="ml-1">{elRow.title}</span>
                            </li>
                          )
                        }
                      )}
                    </ul>
                  </li>
                  <li className="o-dropdown__divider" />
                </>
              )}

              {deleteDisabled ? (
                <DataTooltip position="left" title={deleteDisabledText}>
                  <li className="o-dropdown__list-item flex-container align-middle text-dark disabled">
                    {getDeleteButton()}
                  </li>
                </DataTooltip>
              ) : (
                <li
                  data-open={`delete-element-modal-${element.id}`}
                  className="o-dropdown__list-item flex-container align-middle text-dark">
                  {getDeleteButton()}
                </li>
              )}
            </>
          )}
        </>
      </Dropdown>
      <DeleteElementModal
        id={`delete-element-modal-${element.id}`}
        appendTo="#sceneario-editor-sidebar"
        submitCallback={handleDeleteElement}
        cancelCallback={onCancelDelete}
        element={element}
      />
      <RandomizerModal
        id={`randomizer-modal-${element.id}`}
        appendTo="#scenario-modals" // the radio button doesn't work if appendTo is #sceneario-editor-sidebar
        cancelCallback={onCancelDelete}
        element={element}
      />
    </>
  )
}

export default ElementCardDropdown
